.div_header{
    position: -webkit-sticky; 
  position: sticky;
  top: 0;
  z-index: 2;
  }

.container_select_prodotti{
  width: 480px;
  height: 300px;
  background-color: #FCFCFC;
  box-shadow:rgba(0, 43, 85, 0.1) 0px 8px 10px -5px, rgba(0, 43, 85, 0.05) 0px 16px 24px 2px, rgba(0, 43, 85, 0.1) 0px 6px 30px 5px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
}
.icon_select_prodotti{
  background-color: white;
  border-radius: 50%;
  border: 1px solid grey;
  padding: 10px;
  margin-right: 30px;
}
.container_div_prodotto{
  width: 480px;
  height: 120px;
  background-color: #FCFCFC;
  box-shadow:rgba(0, 43, 85, 0.1) 0px 8px 10px -5px, rgba(0, 43, 85, 0.05) 0px 16px 24px 2px, rgba(0, 43, 85, 0.1) 0px 6px 30px 5px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
}

.icon_close{
 margin-left: 60px;
 cursor: pointer;
 border-radius: 50%;
 padding: 5px;
 
}

.icon_close:hover {background-color: #EBF4FD}


.bgColorGrey{
  background-color: "#F2F2F2";
}


 

